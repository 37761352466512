import Parse, { domToReact } from 'html-react-parser';
import { matchOutletBlockId, formatOutletBlockId } from './outletHelpers';


const parserOptions = {
  replace: node => {

    if (node.name === 'block' && node.attribs && node.attribs.baseorblockid) {
      const blockProps = {
        baseOrBlockId: +node.attribs.baseorblockid,
        children: domToReact(node.children, parserOptions)
      };

      if(node.attribs.blockprops){
        blockProps.blockProps = node.attribs.blockprops;
      }

      return React.createElement('Block', blockProps);
    }

    if (node.name === 'outlet' && node.attribs && node.attribs.outletid) {
      const outletId = node.attribs.outletid;

      return React.createElement('Outlet',
        {
          outletId,
          [`outletName${ outletId }`]: node.attribs[`outletname${ outletId.toLowerCase() }`] || ''
        }
      );
    }

    if (node.name === 'outletcontent' && node.attribs && node.attribs.editblockid) {
      const editBlockId = +node.attribs.editblockid;

      const outletContentProps = {
        outletId: node.attribs.outletid,
        editBlockId: isNaN(editBlockId) ? '' : editBlockId,
        children: domToReact(node.children, parserOptions),
      };

      return React.createElement('OutletContent', outletContentProps);

    }

    if (node.name === 'collection' && node.attribs && node.attribs.baseorcollectionid) {
      const baseOrCollectionId = +node.attribs.baseorcollectionid;
      const properties = node.attribs['data-properties'];

      return React.createElement('Collection',
        {
          baseOrCollectionId: isNaN(baseOrCollectionId) ? '' : baseOrCollectionId,
          children: domToReact(node.children, parserOptions),
          'data-properties': properties ? properties : undefined
        }
      );
    }

    if (node.name === 'collectionelement' && node.attribs && node.attribs.baseorcollectionidandslug) {
      return React.createElement('CollectionElement',
        {
          baseOrCollectionIdAndSlug: node.attribs.baseorcollectionidandslug || '',
          children: domToReact(node.children, parserOptions)
        }
      );
    }

    if (node.name === 'mediaitem' && node.attribs && node.attribs.mediaitemid) {
      const mediaItemId = +node.attribs.mediaitemid;

      return React.createElement('MediaItem',
        {
          mediaItemId: isNaN(mediaItemId) ? (node.attribs.mediaitemid || '') : mediaItemId,
          children: domToReact(node.children, parserOptions),
          className: node.attribs.class || '',
          id: node.attribs.id || '',
        }
      );
    }
  }
}


export default (html, path = []) => {
  return Parse(html, parserOptions);

  // const parsedHtmlWithParents = addPathToNodeProps(parsedHtml, path);
  // return parsedHtmlWithParents;
}
