import React from 'react';

const DEFAULT_STATE = {};

export const BlockNodeContext = React.createContext(DEFAULT_STATE);

class BlockNodeProvider extends React.Component {

  findOutletContent(outletId) {
    // console.log('findOutletContent', outletId);

    const { blockNode } = this.props;
    if(!blockNode) {
      return null;
    }

    let children = blockNode.props.children || [];
    children = Array.isArray(children) ? children : [children];

    // console.log('blockNode children', children);

    const outletContent = children.find(c => c && c.props && c.props.outletId == outletId);

    return outletContent ? outletContent : this.props.findParentOutletContent(outletId);
  }


  render(){
    const { blockNode, parentBlockProps } = this.props;
    const { blockProps } = blockNode.props;

    return (
      <BlockNodeContext.Provider
        value={{
          blockNode: blockNode,
          blockProps: {
            ...(parentBlockProps || {}),
            ...JSON.parse(blockProps || '{}')
          },
          findOutletContent: this.findOutletContent.bind(this),
        }}
      >
        { this.props.children }
      </BlockNodeContext.Provider>
    );
  }
}


BlockNodeProvider.defaultProps = {
  findParentOutletContent: () => {}
}

export default BlockNodeProvider;