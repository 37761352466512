import React from 'react';
import { withRouter } from 'react-router-dom';

import withContext from './withContext';
import { CollectionCacheContext, deconstructCollectionHash } from './CollectionCacheProvider';
import SiteNode from './SiteNode';
import PaginatedCollectionProvider from './PaginatedCollectionProvider'
import { fetchSkip } from './Collection';

class Paginator extends React.Component {
  constructor(props){
    super(props);

    const { next, back, currentSearch } = this.constructNextAndBack(props);

    this.state = {
      next,
      back,
      currentSearch: currentSearch
    }
  }


  componentDidMount() {
    const { next, back, currentSearch } = this.constructNextAndBack(this.props);

    this.setState({ next, back, currentSearch })
  }



  componentDidUpdate(oldProps) {
    const currentSearch = this.props.history.location.search || '';


    if( this.props.collections !== oldProps.collections || this.state.currentSearch !== currentSearch ){
      const { next, back, currentSearch } = this.constructNextAndBack(this.props);

      if(
        next !== this.state.next ||
        back !== this.state.back ||
        currentSearch !== this.state.currentSearch
      ){
        this.setState({ next, back, currentSearch });
      }
    }
  }



  constructNextAndBack(props) {
    const collectionHash = deconstructCollectionHash(
      this.props.baseOrCollectionId || ''
    );


    const fetchedSkip = fetchSkip( collectionHash.slug, this.props.history.location.search );
    collectionHash.skip = fetchedSkip !== null ? fetchedSkip : collectionHash.skip;

    const cachedCollection = this.props.fetchCachedCollection(
      collectionHash
    );


    if( !cachedCollection ){
      return { next: undefined, back: undefined, currentSearch: '' }
    }

    const { collection } = cachedCollection;
    const currentSearch = props.history.location.search;


    const regexMatch = new RegExp(`${ collectionHash.slug }=\\d+`);

    var next = null;
    var back = null;


    if( collectionHash.limit && collectionHash.limit < collection.collection_element_count ){
      if( collectionHash.limit + collectionHash.skip < collection.collection_element_count ){
        const nextPage = `${collectionHash.slug}=${collectionHash.skip+collectionHash.limit}`;

        next = props.history.location.pathname + (
          currentSearch.match( regexMatch ) ?
            currentSearch.replace(regexMatch, nextPage)
          :
            currentSearch ? currentSearch + '&' + nextPage : '?' + nextPage
        );
      }


      if( collectionHash.skip > 0 || collectionHash.skip-collectionHash.limit >= 0 ){
        const diffOrCeiling = Math.min(collection.collection_element_count-collectionHash.limit, collectionHash.skip-collectionHash.limit);
        const lastPage = `${collectionHash.slug}=${Math.max(diffOrCeiling, 0)}`;

        back = props.history.location.pathname + (
          currentSearch.match( regexMatch ) ?
            currentSearch.replace(regexMatch, lastPage)
          :
            currentSearch ? currentSearch + '&' + lastPage : '?' + lastPage
        );
      }
    }

    return { next, back, currentSearch }
  }



  render(){
    const baseOrCollectionId = this.props.baseOrCollectionId;

    return (
      <PaginatedCollectionProvider
        baseOrCollectionId={baseOrCollectionId}
        next={this.state.next}
        back={this.state.back}
      >
        <SiteNode node={this.props.children} />
      </PaginatedCollectionProvider>
    )
  }
}


export default withContext(
  CollectionCacheContext,
  withRouter(
    Paginator
  )
);