import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import interpolate, { maskedEval, INTERPOLATION_REGEX, EXPRESSION_REGEX } from './interpolate';
import Block from './Block';
import MediaItem from './MediaItem';
import Collection from './Collection';
import CollectionElement from './CollectionElement';
import Outlet from './Outlet';
import OutletContent from './OutletContent';
import Paginator from './Paginator';
import PaginatedCollection from './PaginatedCollection';
import BlockSearch from './BlockSearch';
import BlockSearchElement from './BlockSearchElement';
import { CollectionElementContext } from './CollectionElementProvider';
import { SiteDataContext } from './SiteDataProvider';
import withContext from './withContext';
import parseHtml from './parseHtml';
import BlockNodeProvider, { BlockNodeContext } from './BlockNodeProvider';
import { SitePreviewContext } from './SitePreviewProvider';
import { MediaItemContext } from './MediaItemProvider';
import { createInterpolationContext } from './interpolationHelpers';
import { SitePagesContext } from './SitePagesProvider';
import { PaginatedCollectionContext } from './PaginatedCollectionProvider';
import { BlockSearchContext } from './BlockSearchProvider';
import { BlockSearchElementContext } from './BlockSearchElementProvider';
import { NestedCollectionElementContext } from './NestedCollectionElementProvider';


const a = props => {
  const href = props.href || '';
  // Remove query params and hashes
  const baseUrl = href.replace(/\?[^\/]+$/, '');

  let matchedRoute = false;

  const pages = props.pages || [];
  pages.forEach(p => {
    let route = p.path;

    // Generalize paths to Collection Element pages
    if( route.match( /\/:[^\/]+/ ) ){
      route = route.replace( /:[^\/]+/, '[^\\/]+' );
    }

    if( baseUrl.match( new RegExp(`^${ route.replace('/', '\/') }$`, 'g') ) ){
      matchedRoute = true;
    }
  })


  // Remove props that are not valid DOM elements
  const tagProps = { ...props };
  delete tagProps.disableClientSideRendering;
  delete tagProps.pages;


  if (
    matchedRoute &&
    !props.disableClientSideRendering &&
    ( href.startsWith('/') || href.startsWith('./') )
  ){
    return <Link {...tagProps} to={href}/>
  } else {
    return <a {...tagProps}/>;
  }
}


const input = props => {
  const { value, ...otherProps } = props;
  // This is weird, but to handle submit buttons
  // we set the default value equal to any defined value
  // to make the input uncontroller
  return <input defaultValue={value} {...otherProps} />
}


const htmlToReactNodeMap = node => {
  const nodeMap = {
    a,
    input,
    Block,
    MediaItem,
    Collection,
    CollectionElement,
    Outlet,
    OutletContent,
    Paginator,
    BlockSearch,
    BlockSearchElement,
    PaginatedCollection
  };
  return nodeMap[node.type] || node.type;
};


class SiteNode extends React.PureComponent {


  interpolateStringNode(node) {
    try {
      const context = this.getInterpolationContext();
      return interpolate(node, context);
    }
    catch(e) {
      // console.log('Interpolation error on', node, e);
      // console.log('context', context);
      return node;
    }

  }


  interpolateHtmlNode(string) {
    // console.log('interpolateHtmlNode', string);
    if(EXPRESSION_REGEX.test(string)) {
      const expressionResult = this.interpolateStringNode(string);
      return expressionResult === string ? string : this.interpolateHtmlNode(expressionResult);
    }


    const parsedString = parseHtml(string);
    if (typeof parsedString !== 'string') {
      // There is a weird issue with the parser here with the string "<-- Back to Models"
      if(Array.isArray(parsedString) && !parsedString.length) {
          // console.log('Weird case...', string, parsedString);
        return string;
      }

      // console.log('Not a string', string, parsedString);
      return this.nodeChildren(parsedString);
    }

    // Don't do anything if there's nothing to interpolate.
    const matches = parsedString.match(INTERPOLATION_REGEX);
    if (!matches) {
      // console.log('No matches', string);
      return parsedString;
    }

    // Stop trying to interpolate, if the output is the same as the input
    // that is - you've hit an interpolation error

    // You have to parse the HTML after every step, because
    // you could have gotten more html!

    const splits = parsedString.split(INTERPOLATION_REGEX);

    if (matches.length === 1 && splits.filter(s => s).length === 0) {
      const s = this.interpolateStringNode( matches[0] );
      if (s === matches[0]) {
        // A failed interpolation just returns and stops recursion.
        return s;
      }
      // New stuff, reparse it
      // console.log('New stuff, reparse it', s);
      return this.interpolateHtmlNode(s);
    }

    const splitChildren = [];

    splits.forEach((s,i) => {
      splitChildren.push(s);
      if(i == matches.length) return '';


      const childNode = this.interpolateHtmlNode( matches[i] );
      splitChildren.push(childNode);
    });

    return splitChildren;
  }



  interpolateProps(props) {
    const interpolatedProps = {...( props || {} )};

    Object.keys(interpolatedProps).forEach(k => {
      const value = props[k];
      if(typeof value === 'string') {
        interpolatedProps[k] = this.interpolateStringNode(value);
      }
      else if (k === 'style') {
        // console.log('Interpolating style', props[k]);
        interpolatedProps[k] = this.interpolateProps(props[k]);
        // console.log('Finished style', interpolatedProps[k])
      }
    });

    return interpolatedProps;
  }


  getInterpolationContext() {
    const { siteData, blockProps, history, location, match, ...otherProps } = this.props;
    const siteInterpolationContext = createInterpolationContext(siteData, siteData.site.id);

    return {
      ...otherProps,
      ...siteInterpolationContext,
      props: blockProps || {},
      router: {
        history,
        location,
        match,
      },
    };
  }


  nodeChildren(children) {
    if (!children) {
      return null;
    }

    const siteNodeProps = {
      onClick: this.props.onClick,
      onFocus: this.props.onFocus,
      baseOrBlockId: this.props.baseOrBlockId,
    };


    if (typeof children === 'string') {
      // console.log('string', children);
      return this.interpolateHtmlNode(children);
    } else if (Array.isArray(children)) {
      return children.map((c, i) => (
        <SiteNodeWithContext {...siteNodeProps} node={c} key={i} />
      ));
    } else if (typeof children === 'object') {
      return <SiteNodeWithContext {...siteNodeProps} node={children} key={0} />;
    }
  }


  wrapBlockNode(Node, node) {
    if(node.type !== 'Block') {
      return Node;
    }


    return (
      <BlockNodeProvider
        blockNode={node}
        parentBlockProps={this.props.blockProps}
        findParentOutletContent={this.props.findOutletContent}
      >
        { Node }
      </BlockNodeProvider>
    );
  }


  render() {
    const { node } = this.props;

    if (!node) {
      return null;
    }

    if (typeof node === 'string') {
      return this.interpolateHtmlNode(node);
    } else if (Array.isArray(node)) {
      return this.nodeChildren(node);
    }


    const Node = htmlToReactNodeMap(node);

    let isVisible = true;
    if(node.props['data-if']) {
      isVisible = false;
      try {
        isVisible = maskedEval(node.props['data-if'], this.getInterpolationContext());
      }
      catch(e) {
        // console.log('interpolation error', e);
      }
    }

    if(!isVisible) {
      return null;
    }



    const interpolatedNodeProps = this.interpolateProps(node.props);

    const { baseOrBlockId } = this.props;
    if(baseOrBlockId) {
      interpolatedNodeProps['data-base-or-block-id'] = baseOrBlockId;
    }

    if(this.props.highlightBlocks) {
      interpolatedNodeProps.tabIndex = 0;
    }

    interpolatedNodeProps.onClick = this.props.onClick;
    interpolatedNodeProps.onFocus = this.props.onFocus;

    if(node.type === 'a'){
      interpolatedNodeProps.disableClientSideRendering = this.props.siteData.site.disable_client_side_rendering;
      interpolatedNodeProps.pages = this.props.pages;
    }

    return this.wrapBlockNode(
      <Node {...interpolatedNodeProps}>
        { this.nodeChildren(node.props.children) }
      </Node>,
      node
    );
  }

}

const SiteNodeWithContext = withRouter(
  withContext(
    SitePreviewContext,
    SitePagesContext,
    SiteDataContext,
    BlockSearchContext,
    BlockSearchElementContext,
    CollectionElementContext,
    PaginatedCollectionContext,
    NestedCollectionElementContext,
    MediaItemContext,
    BlockNodeContext,
    SiteNode
  )
);

export default SiteNodeWithContext;



export const removeDataPathAttributes = function( html) {
  return html.replace(/\s?data-path="[^"]*"/g, '');
}



export class NodeHtml extends React.Component {
  nodeChildren(children){
    if (!children) {
      return null;
    } else if (typeof children === 'string') {
      return children;
    } else if (Array.isArray(children)) {
      return children.map((c, i) => (
        <NodeHtml node={c} key={i} />
      ));
    } else if (typeof children === 'object') {
      return <NodeHtml node={children} key={0} />;
    }
  };

  render() {
    const { node } = this.props;

    if (!node) {
      return null;
    }

    if (typeof node === 'string') {
      return node;
    } else if (Array.isArray(node)) {
      return this.nodeChildren(node);
    }

    // Node must be an object;
    // const isHtmlNode = !node.type || node.type[0] == node.type[0].toLowerCase();
    const Node = node.type;

    return (
      <Node {...node.props}>
        {this.nodeChildren(node.props.children)}
      </Node>
    );

  }
}