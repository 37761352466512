export default (s, context) => {
  const match = s.match(INTERPOLATION_REGEX);

  if (!match) {
    return s;
  }

  // console.log('Interpolation match!', match);

  match.forEach(m => {
    const expression = m.replace('{{','').replace('}}','');
    const result = maskedEval(expression, context);

    // Only replace the value if the result is defined
    if( result !== undefined ){
      s = s.replace(m, result);
    }
  });

  return s;
}


export function maskedEval(expression, mask = {})
{
    // console.log(`maskedEval of "${expression}" with context`, mask);
    // being evaluated.
    // mask global properties
    for (let p in this) {
      mask[p] = undefined;
    }

    // execute script in private context
    // execute with try/catch to ensure that multiple {{ ... }} within same s is caught3
    let evaluatedExpression = '{{'+expression+'}}';

    try{
      evaluatedExpression = (new Function( "with(this) { return " + expression + "}")).call(mask);
    } catch (error){
      // console.log('maskedEval error with expression: ', expression)
      // console.log('error is ',error)
    }

    return evaluatedExpression
}


export const INTERPOLATION_REGEX = /{{[^{]*?}}/g;
export const EXPRESSION_REGEX = /^\s*{{.+}}\s*$/;
