import React from 'react';
import withContext from './withContext';
import { MediaItemCacheContext } from './MediaItemCacheProvider';
import GlobalConstants from '../GlobalConstants';
import SiteNode from './SiteNode';
import MediaItemProvider from './MediaItemProvider';
import optimizeImageNode from './imageOptimizationHelpers';


class MediaItem extends React.Component {

  constructor(props) {
    super(props);

    const cachedMediaItem = this.props.fetchCachedMediaItem(props.mediaItemId);

    // console.log('cachedMediaItem', cachedMediaItem);
    const mediaItem = (
      props.mediaItem || cachedMediaItem
    );

    this.state = { mediaItem };
  }



  componentDidMount() {
    if(this.state.mediaItem) {
      return;
    }

    this.props.fetchRemoteMediaItem(this.props.mediaItemId)
      .then(mediaItem => this.setState({ mediaItem }) )
      .catch(error => this.setState({ mediaItem: null }) )
    ;
  }


  constructNode() {
    const { mediaItem } = this.state;
    let node;

    if( !mediaItem || !mediaItem.mime_type ){
      return;
    } else if( mediaItem.mime_type.includes('image') ){
      node = optimizeImageNode( mediaItem );
    } else if( mediaItem.mime_type.includes('video') ){
      node = {
        props: {
          "data-path": 'props.children.0',
          controls: true,
          children: [
            {
              props: {
                "data-path": "props.children.0.props.children.0",
                src: `${ mediaItem.url }`,
                type: `${ mediaItem.mime_type }`,
              },
              type: "source"
            },
            "Your browser does not support HTML5 video."
          ]
        },
        type: "video"
      }
    } else if( mediaItem.mime_type.includes('audio') ){
      node = {
        props: {
          "data-path": 'props.children.0',
          controls: true,
          children: [
            {
              props: {
                "data-path": "props.children.0.props.children.0",
                src: `${ mediaItem.url }`,
                type: `${ mediaItem.mime_type }`,
              },
              type: "source"
            },
            "Your browser does not support the audio element."
          ]
        },
        type: "audio"
      }
    } else if( mediaItem.mime_type.includes('application') ){
      node = {
        props: {
          "data-path": 'props.children.0',
          children: [
            "DOCUMENT HERE"
          ],
          // href: `${ mediaItem.url }`,
          href: `${ GlobalConstants.goBoostPartnersBaseUrl }/api/core/media_items/${ mediaItem.id }/download`,
          target: '_blank',
        },
        type: "a"
      }
    }

    node.props.id = this.props.id ? this.props.id : undefined;
    node.props['data-if'] = this.props['data-if'] ? this.props['data-if'] : undefined;
    node.props.style = this.props.style ? this.props.style : undefined;
    node.props.className = this.props.className ? this.props.className : undefined;
    node.props.alt = this.props.alt ? this.props.alt : undefined;

    return node;
  }


  hasChildren() {
    const nodeChildren = this.props.children;
    const nodeChildrenText = (
      nodeChildren && nodeChildren.props && Object.keys( nodeChildren.props ).includes('text') && !nodeChildren.props.children ?
        nodeChildren.props.text
      :
        null
    );

    const isTextEmpty = text => (
      typeof text === 'string' &&
      (
        !text ||
        text.trim() === '\u00B6'
      )
    );

    if(
      !nodeChildren ||
      isTextEmpty( nodeChildrenText ) ||
      isTextEmpty( nodeChildren )
    ){
      return false;
    } else if( Array.isArray( nodeChildren ) ){
      return Boolean( nodeChildren.filter(c => c && c != "\u00B6" ).length );
    } else {
      return true;
    }
  }


  render(){
    const node = this.hasChildren() ? this.props.children : this.constructNode();

    return (
      <MediaItemProvider mediaItem={this.state.mediaItem}>
        <SiteNode
          node={node}
          onClick={this.props.onClick ? this.props.onClick : undefined}
          onFocus={this.props.onFocus ? this.props.onFocus : undefined}
        />
      </MediaItemProvider>
    );
  }

}



export default withContext(
  MediaItemCacheContext,
  MediaItem
);
