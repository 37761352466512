import React from 'react';
import { withRouter } from 'react-router-dom';
import withContext from './withContext';
import { CollectionCacheContext, deconstructCollectionHash } from './CollectionCacheProvider';
import NestedCollectionElementProvider, { NestedCollectionElementContext } from './NestedCollectionElementProvider';
import CollectionElementProvider from './CollectionElementProvider';
import SiteNode from './SiteNode';

class Collection extends React.Component {

  constructor(props) {
    super(props);

    const collectionHash = deconstructCollectionHash(
      props.baseOrCollectionId || ''
    );

    const fetchedSkip = fetchSkip( collectionHash.slug, props.history.location.search );
    const skip = fetchedSkip !== null ? fetchedSkip : collectionHash.skip;
    collectionHash.skip = skip;

    const cachedCollection = this.props.fetchCachedCollection(
      collectionHash
    );

    this.state = {
      collection: cachedCollection,
      skip: fetchedSkip
    };
  }



  componentDidMount() {
    this.constructState();
  }


  componentDidUpdate(oldProps) {
    const collectionHash = deconstructCollectionHash(
      this.props.baseOrCollectionId || ''
    );

    const skip = fetchSkip( collectionHash.slug, this.props.history.location.search );

    if( this.props.collections !== oldProps.collections || skip !== this.state.skip ){
      this.constructState()
    }
  }



  constructState() {
    const collectionHash = deconstructCollectionHash(
      this.props.baseOrCollectionId || ''
    );

    const fetchedSkip = fetchSkip( collectionHash.slug, this.props.history.location.search );
    const skip = fetchedSkip !== null ? fetchedSkip : collectionHash.skip;
    collectionHash.skip = skip;

    const cachedCollection = this.props.fetchCachedCollection(
      collectionHash
    );


    const findOrFetchCollection = () => (
      cachedCollection ?
        Promise.resolve(cachedCollection)
      :
        this.props.fetchRemoteCollection(
          collectionHash
        )
    );


    return findOrFetchCollection()
      .then(collection => this.setState({ collection, skip: fetchedSkip }) )
      .catch(error => this.setState({ collection: null, skip: fetchedSkip }))
    ;
  }


  render(){
    const collectionElements = this.state.collection ?
        this.state.collection.collectionElements || []
      :
        []
    ;

    return (
      collectionElements.map((e, i) => (
        <NestedCollectionElementProvider
          element={e}
          alias={this.props.alias}
          nestedElements={this.props.nestedElements}
          key={i}
        >
          <CollectionElementProvider element={{ ...e }}>
            <SiteNode node={this.props.children}/>
          </CollectionElementProvider>
        </NestedCollectionElementProvider>
      ))
    );

  }

}


Collection.defaultProps = {
  baseOrCollectionId: '',
}



export default withContext(
  NestedCollectionElementContext,
  CollectionCacheContext,
  withRouter(
    Collection
  )
)



export const fetchSkip = function( slug, search ){
  if( slug && search ){
    const from = search.match( new RegExp( `${ slug }=(\\d+)` ) );

    return from ? +from[1] : null
  }

  return null
}