import React from 'react';
import withContext from './withContext';
import { CollectionCacheContext } from './CollectionCacheProvider';
import Collection from './Collection'
import { PaginatedCollectionContext } from './PaginatedCollectionProvider';

const paginatedCollection = props => (
  <Collection
    baseOrCollectionId={props.baseOrCollectionId}
  >
    { props.children }
  </Collection>
);


export default withContext(CollectionCacheContext, PaginatedCollectionContext, paginatedCollection)