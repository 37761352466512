import interpolate from './interpolate';
import Parse from 'html-react-parser';
import { surveyCheckboxValue, surveyInputValue, surveyColorValue, externalData, submitFormOpportunity, fetchSystemStatus } from './siteDataHelpers';
import * as format from './format';




export const createInterpolationContext = function(siteData, siteId){
  return {
    ...siteData,
    format,
    surveyColorValue: variableName => (
      surveyColorValue(variableName, siteData)
    ),
    surveyInputValue: variableName => (
      surveyInputValue(variableName, siteData)
    ),
    surveyCheckboxValue: (variableName, checkboxText) => (
      surveyCheckboxValue(variableName, checkboxText, siteData)
    ),
    externalData: key => (
      externalData(key, siteData)
    ),
    submitFormOpportunity: (formData, successCallback, errorCallback) => (
      submitFormOpportunity(
        formData,
        successCallback,
        errorCallback,
        siteData.company.id,
        siteId
      )
    ),
    fetchSystemStatus
  };
};



export const interpolateData = function(data, siteData, siteId){
  try{
    return interpolate( data, createInterpolationContext(siteData, siteId) )
  } catch (e){
    return data
  }
};