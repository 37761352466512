import React from 'react';
import { withRouter } from 'react-router-dom';
import { CollectionElementCacheContext } from './CollectionElementCacheProvider';
import withContext from './withContext';
import CollectionElementProvider, { CollectionElementContext, DEFAULT_ALIAS } from './CollectionElementProvider';
import NestedCollectionElementProvider, { NestedCollectionElementContext } from './NestedCollectionElementProvider';
import SiteNode from './SiteNode';


class CollectionElement extends React.Component {

  constructor(props) {
    super(props);

    const cachedCollectionElement = props.fetchCachedCollectionElement(
      parseSlugParams(props)
    );

    const collectionElement = (
      props.collectionElement || cachedCollectionElement
    );

    this.state = { collectionElement };
  }


  componentDidMount() {
    // console.log('CollectionElement.componentDidMount', this.state);
    if(this.state.collectionElement) {
      return;
    }

    this.props.fetchRemoteCollectionElement(parseSlugParams(this.props))
      .then(collectionElement => this.setState({ collectionElement }) )
      .catch(error => this.setState({ collectionElement: null }) )
    ;
  }


  componentWillReceiveProps(nextProps){
    const idAndSlug = parseSlugParams(this.props);
    const nextIdAndSlug = parseSlugParams(nextProps);

    if (nextIdAndSlug !== idAndSlug) {
      this.props.fetchRemoteCollectionElement(nextIdAndSlug)
        .then(collectionElement => this.setState({ collectionElement }) )
        .catch(error => this.setState({ collectionElement: null }) )
      ;
    }
  }


  render(){
    let { collectionElement } = this.state;

    // Prevents rendering {{ element.key }} before the element is loaded.
    if(!collectionElement) {
      return null;
    }

    collectionElement = ( collectionElement ?
        {
          ...collectionElement,
          id: collectionElement.id,
          name: collectionElement.name,
          slug: collectionElement.slug
        }
      :
        null
    );


    return (
      <NestedCollectionElementProvider
        element={collectionElement}
        alias={this.props.alias}
        nestedElements={this.props.nestedElements}
      >
        <CollectionElementProvider
          element={collectionElement}
        >
          <SiteNode node={this.props.children}/>
        </CollectionElementProvider>
      </NestedCollectionElementProvider>
    );
  }

}


const parseSlugParams = props => {
  const split = props.baseOrCollectionIdAndSlug.split('-');
  let collectionElementSlug = split.slice(1).join('-');

  if (collectionElementSlug.startsWith(':')) {
    collectionElementSlug = props.match.params[collectionElementSlug.slice(1)] || '';
    return `${split[0]}-${collectionElementSlug}`;
  } else {
    return props.baseOrCollectionIdAndSlug;
  }
}


export default withRouter(
  withContext(
    CollectionElementCacheContext,
    NestedCollectionElementContext,
    CollectionElementContext,
    CollectionElement
  )
);
