import React from 'react';
import Axios from 'axios';

const DEFAULT_STATE = {};

export const CollectionCacheContext = React.createContext(DEFAULT_STATE);

class CollectionCacheProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = props.state || {};

    // this.state = {
    //   id-<sort>-<skip>-<limit>: {
    //     collection: {},
    //     collectionElements: []
    //   }
    // }
  }






  fetchCachedCollection( collectionHash ) {
    const hash = stateHash( collectionHash );

    const collection = this.state[ hash ];


    if( collection && collectionHash.properties && collectionHash.properties.length ){
      const propertiesCached = collection.collectionElements.every(
        e => collectionHash.properties.every(p => Object.keys(e).includes( p ))
      );

      if( !propertiesCached ){
        return
      }
    }


    return collection
  }



  fetchRemoteCollection( collectionHash ) {
    if(typeof window === 'undefined' ) {
      return Promise.resolve({ collection: null, collectionElements: null});
    }

    var { baseOrCollectionId, sort, limit, skip, properties} = collectionHash;


    sort = sort && sort.length ? sort.join(',') : undefined;

    limit = limit && +limit >= 0 ? +limit : undefined;

    skip = skip || undefined;

    properties = properties && properties.length ? properties : undefined


    const params = {
      role_type: this.props.site.role_type,
      role_type_id: this.props.site.role_type_id,
      properties,
      sort,
      skip,
      limit
    };


    return Axios.get(
      `/api/sites/collections/${baseOrCollectionId}/base`,
      { params }
    ).then(response => {
      const collectionModel = {
        collection: response.data.collection,
        collectionElements: response.data.collection_elements,
      };

      const hash = stateHash( collectionHash );

      this.setState({ [ hash ]: collectionModel });

      return collectionModel
    });
  }



  render(){
    return (
      <CollectionCacheContext.Provider
        value={{
          collections: this.state,
          fetchCachedCollection: this.fetchCachedCollection.bind(this),
          fetchRemoteCollection: this.fetchRemoteCollection.bind(this)
        }}
      >
        { this.props.children }
      </CollectionCacheContext.Provider>
    );
  }
}

export default CollectionCacheProvider



export const constructCollectionHash = function(
  options={
    baseOrCollectionId: '',
    slug: '',
    sort: [],
    skip: '',
    limit: '',
    properties: []
  }
) {
  const baseOrCollectionId = options.baseOrCollectionId || '';
  const slug = options.slug || '';
  const sort = options.sort && options.sort.length ? options.sort.join(',') : '';
  const skip = +options.skip ? options.skip : '';
  const limit = +options.limit ? options.limit : '';
  const properties = options.properties && options.properties.length ? options.properties.filter(p => p).join(',') : '';


  return (
  `${ baseOrCollectionId || '' }-slug:${ slug }-sort:${ sort }-skip:${ skip }-limit:${ limit }-properties:${ properties }`
  )
}


export const stateHash = function( hash ) {
  return `${ hash.baseOrCollectionId }-${ hash.sort }-${ +hash.skip ? hash.skip : '' }-${ +hash.limit ? hash.limit : ''  }`
}



export const deconstructCollectionHash = function(collectionHash) {
  // Legacy issue: baseOrCollectionId may be an integer
  collectionHash = String( collectionHash );

  const hashMatch = collectionHash.match(
    new RegExp(`${ baseOrCollectionIdRegex() }`)
  );

  var baseOrCollectionId;
  var slug;
  var sort = [];
  var skip;
  var limit;
  var properties = [];


  if( hashMatch ){
    baseOrCollectionId = +hashMatch[1] || +hashMatch[2] || null;
    slug = hashMatch[3];
    sort = hashMatch[4] ? hashMatch[4].split(',') : [];
    // Sort can contain multiple groups, so retrieve other values from end
    skip = +hashMatch[ hashMatch.length - 3 ] || null;
    limit = +hashMatch[ hashMatch.length - 2 ] || null;
    properties = hashMatch[ hashMatch.length - 1 ] ? hashMatch[ hashMatch.length - 1 ].split(',').filter(p => p) : [];
  }


  return {
    baseOrCollectionId,
    slug,
    sort,
    skip,
    limit,
    properties,
  }
}



export const oldBaseOrCollectionRegex = function(){
  return(
    `(\\d*)`
  )
}


export const newBaseOrCollectionRegex = function(){
  return(
    `(\\d*)-slug:([^"]*)-sort:([^"]*)-skip:(\\d*)-limit:(\\d*)-properties:([^"]*)`
  )
}



export const baseOrCollectionIdRegex = function(){
  return(
    `^${ oldBaseOrCollectionRegex() }$|^${ newBaseOrCollectionRegex() }$`
  )
}