import React from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import withContext from './withContext';
import SiteNode from './SiteNode';
import { CollectionElementContext } from './CollectionElementProvider';
import { SiteDataContext } from './SiteDataProvider';
import { SitePreviewContext } from './SitePreviewProvider';
import { BlockCacheContext } from './BlockCacheProvider';
import { BlockNodeContext } from './BlockNodeProvider';
import { PageContext } from './PageProvider';
import interpolate from './interpolate';
import { createInterpolationContext } from './interpolationHelpers';

class Block extends React.Component {

  constructor(props) {
    super(props);

    const cachedBlock = this.props.fetchCachedBlock(props.baseOrBlockId);

    const data = (
      props.data
        ||
        cachedBlock ? cachedBlock.data : null
    );

    const css = (
      props.css
        ||
        cachedBlock ? cachedBlock.css : null
    );


    const hasJs = (
      props.hasJs
        ||
        cachedBlock ? cachedBlock.hasJs : null
    );


    const js = (
      props.js
        ||
        cachedBlock ? cachedBlock.js : null
    );

    this.state = {
      data,
      css,
      hasJs,
      js,
      scriptIds: []
    };
  }


  componentDidMount() {
    if (this.state.data && this.state.hasJs && this.state.js) {
      this.appendJs();
      return;
    }

    const { baseOrBlockId } = this.props;
    this.props.fetchRemoteBlock(baseOrBlockId)
      .then(block => {
        if( block.hasJs ){
          return this.props.fetchRemoteBlockJs(baseOrBlockId)
            .then(block => block)
        } else {
          return Promise.resolve(block)
        }
      }).then(block => {
        this.setState({ data: block.data, css: block.css, hasJs: block.hasJs, js: block.js });
      })
      .catch(error => {
        console.log('Error retrieving block', baseOrBlockId, error);
      });
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate', this.props.baseOrBlockId);
    this.appendJs();
  }

  componentWillUnmount() {
    const { baseOrBlockId } = this.props;
    const { scriptIds, hasJs } = this.state;

    if (hasJs && scriptIds) {
      scriptIds.forEach(s => this.removeJs(s));
    }
  }

  appendJs() {
    if (!document || !this.state.hasJs || !this.state.js) return;

    const { scriptIds, js } = this.state;

    if (
      scriptIds.length && scriptIds.map(
        id => document.getElementById(id)
      ).every(s => s)
    ) {
      return;
    }


    const newScriptIds = [];
    const scripts = [];


    const pushObjectScript = script => {
      let objectScript;

      if( script.props.src ){
        objectScript = { src: script.props.src };
      } else {
        objectScript = { innerHTML: script.props.dangerouslySetInnerHTML.__html };
      }

      scripts.push( objectScript );
    }


    if( typeof js === 'string' ){
      scripts.push({ innerHTML: js });
    } else if ( typeof js === 'object' && !Array.isArray( js ) && js.type === 'script' ){
      pushObjectScript( js );
    } else {
      js.forEach(j => {
        if( typeof j === 'object' && j.type === 'script' ){
          pushObjectScript( j );
        }
      })
    }



    scripts.forEach(s => {
      const scriptId = shortid.generate();

      const script = document.createElement('script');
      script.id = scriptId;

      if( s.src ){
        script.src = s.src;
      } else{
        script.innerHTML = s.innerHTML;
      }

      try {
        document.head.appendChild(script);
        newScriptIds.push( scriptId );
      } catch (error) {
        console.log('Error loading script.')
      }
    })

    this.setState({ scriptIds: newScriptIds });
  }


  removeJs(scriptId) {
    const script = document.getElementById(scriptId);
    if (script) {
      // console.log('removeJs', scriptId);
      script.remove();
    }
  }


  interpolateCSS(css) {
    const { siteData, blockProps, history, location, match, ...otherProps } = this.props;
    const siteInterpolationContext = createInterpolationContext(siteData, siteData.site.id);

    const interpolationContext = {
      ...otherProps,
      ...siteInterpolationContext,
      props: blockProps || {},
      router: {
        history,
        location,
        match,
      },
    };

    try{
      return interpolate(css, interpolationContext);
    } catch (e){
      return css;
    }
  }


  onActivateBlock(e){
    e.stopPropagation();

    const element = e.currentTarget.closest('[data-base-or-block-id]');
    const { baseOrBlockId } = element.dataset;
    // console.log('baseOrBlockId', baseOrBlockId);
    const block = this.props.getBlock(baseOrBlockId);
    window.baseOrBlockId = baseOrBlockId;
    window.setActiveNodeLabel(e.target, block.name);
  }


  preventDefault(e) {
    e.preventDefault();
  }


  render() {
    const { data, css } = this.state;

    if (!data) {
      return null;
    }



    return (
      <React.Fragment
      >
        <SiteNode
          node={data.props.children}
          baseOrBlockId={this.props.baseOrBlockId}
          onClick={this.props.preventDefault ? this.preventDefault : undefined}
          onFocus={this.props.highlightBlocks ? this.onActivateBlock.bind(this) : undefined}
        />
        {css ? <style>{ this.interpolateCSS(css) }</style> : null}
      </React.Fragment>
    );

  }

}





export default withRouter(
  withContext(
    PageContext,
    SitePreviewContext,
    SiteDataContext,
    CollectionElementContext,
    BlockNodeContext,
    BlockCacheContext,
    Block
  )
);