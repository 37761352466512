import React from 'react';


export class OutletContent extends React.Component {

  render() {
    return this.props.children;
  }

}

export default OutletContent;
