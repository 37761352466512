import React from 'react';

const DEFAULT_STATE = {};

export const SitePreviewContext = React.createContext(DEFAULT_STATE);

class SitePreviewProvider extends React.Component {

  render(){
    const { isPreview, highlightBlocks, preventDefault } = this.props;

    return (
      this.props.isPreview ?
        <SitePreviewContext.Provider
          value={{
            isPreview,
            highlightBlocks,
            preventDefault,
          }}
        >
          { this.props.children }
        </SitePreviewContext.Provider>
      :
        this.props.children
    );
  }
}


export default SitePreviewProvider;
