import React from 'react';
import SiteNode from './SiteNode';
import withContext from './withContext';
import { BlockNodeContext } from './BlockNodeProvider';



export class Outlet extends React.Component {

  constructor(props){
    super(props);
    const outletContent = this.props.findOutletContent(this.props.outletId) || null;

    this.state = {
      outletContent
    };
  }


  render(){
    const nameKey = `outletName${ this.props.outletId }`;
    const { outletContent } = this.state;

    if (!outletContent) {
      return (
        <div style={styles.noData}>
          {this.props[nameKey] || 'Outlet'}
        </div>
      );
    }


    return (
      <SiteNode node={outletContent} />
    );
  }

}

Outlet.defaultProps = {
  findOutletContent: () => {}
};


const styles = {
  noData: {
    padding: 15,
    textAlign: 'center',
    fontSize: 12,
    color: '#777777',
    backgroundColor: 'whitesmoke'
  }
};

export default withContext(BlockNodeContext, Outlet);
