import React from 'react';

const DEFAULT_STATE = {};

export const SitePagesContext = React.createContext(DEFAULT_STATE);

class SitePagesProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render(){

    return (
      <SitePagesContext.Provider
        value={{
          pages: this.props.pages
        }}
      >
        { this.props.children }
      </SitePagesContext.Provider>
    );
  }
}

SitePagesProvider.defaultProps = {
  pages: []
}


export default SitePagesProvider
