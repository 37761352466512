import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import withContext from './withContext';
import { SiteDataContext } from './SiteDataProvider';
import interpolate from './interpolate';
import { createInterpolationContext } from './interpolationHelpers';

const DEFAULT_STATE = {};

export const PageContext = React.createContext(DEFAULT_STATE);

class PageProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
      title: this.interpolateTitle(props.page ? props.page.title || '' : '')
    };

  }

  updateTitleWithContext(extraContext={}){
    const { title } = this.state;
    const newTitle = this.interpolateTitle(
      this.state.page.title,
      extraContext
    );

    if(newTitle !== title) {
      this.props.setInitialTitle(newTitle);
      this.setState({ title: newTitle });
    }
  }

  interpolateTitle(title, extraContext={}) {
    try {
      const { siteData, history, location, match } = this.props;
      const siteInterpolationContext = createInterpolationContext(siteData, siteData.site.id);

      return interpolate(title,
        {
          ...extraContext,
          ...siteInterpolationContext,
          router: {
            history,
            location,
            match,
          },
        }
      );
    }
    catch(e) {
      return title;
    }
  }


  render(){
    return (
      <PageContext.Provider
        value={{
          page: this.state.page,
          updateTitleWithContext: this.updateTitleWithContext.bind(this),
        }}
      >
        <Helmet>
          <title>{ this.state.title.includes('{{') ? 'Loading...' : this.state.title }</title>
        </Helmet>
        { this.props.children }
      </PageContext.Provider>
    );
  }
}


PageProvider.defaultProps = {
  setInitialTitle: () => {},
};

export default withRouter(
  withContext(SiteDataContext, PageProvider)
);
