import React from 'react';
import { withRouter } from 'react-router-dom';



const DEFAULT_STATE = {};

export const BlockSearchCacheContext = React.createContext(DEFAULT_STATE);

class BlockSearchCacheProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.state || {};
  }



  fetchCachedBlockSearch( blockSearchId ){
    const blockSearch = this.state[ blockSearchId ];
    return blockSearch;
  }



  render(){
    return (
      <BlockSearchCacheContext.Provider
        value={{
          fetchCachedBlockSearch: this.fetchCachedBlockSearch.bind( this )
        }}
      >
        { this.props.children }
      </BlockSearchCacheContext.Provider>
    );
  }
}



BlockSearchCacheProvider.defaultProps = {
}



export default withRouter(
  BlockSearchCacheProvider
);



export const constructBlockSearchHash = function(
  options={
    slug: '',
    field: '',
    query: '',
    limit: '',
    offset: '',
  }
){
  const slug = options.slug ? options.slug : '';
  const field = options.field ? options.field : '';
  const query = options.query || '';
  const limit = +options.limit ? options.limit : '';
  const offset = +options.offset ? options.offset : '';


  return (
    `slug:${ slug }-field:${ field }-offset:${ offset }-limit:${ limit }-query:${ query }`
  );
}



export const blockSearchRegex = function(){
  return(
    `slug:([^"]*)-field:([^"]*)-offset:(\\d*)-limit:(\\d*)-query:([^"]*)`
  );
}



export const deconstructBlockSearchHash = function( blockSearchHash ){
  blockSearchHash = String( blockSearchHash );

  const hashMatch = blockSearchHash.match(
    new RegExp(`${ blockSearchRegex() }`)
  );

  let slug;
  let field;
  let offset;
  let limit;
  let query;


  if( hashMatch ){
    slug = hashMatch[1] || null;
    field = hashMatch[2] || null;
    offset = +hashMatch[3] || null;
    limit = +hashMatch[4] || null;
    query = hashMatch[5] || null;
  }


  return {
    slug,
    field,
    offset,
    limit,
    query,
  }
}