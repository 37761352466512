export const surveyInputValue = function(variableName, siteData) {
  if (!siteData || !siteData.survey_results) {
    return '';
  }

  const result = siteData.survey_results.find(
    r => r.question_type === 'input' && r.variable_name === variableName
  );

  const value = result ? result.response_data || '' : '';
  const questionTemplate = result ? result.question_data : '';

  return questionTemplate && questionTemplate.template ? questionTemplate.template.replace('{}', value) : value
};


export const surveyCheckboxValue = function(variableName, checkboxText, siteData) {
  if (!siteData || !siteData.survey_results) {
    return '';
  }

  const result = siteData.survey_results.find(
    r => r.question_type === 'checkbox' && r.variable_name === variableName
  );

  if (!result) {
    return '';
  }

  const checkbox = result.response_data.find(
    d => d.text === checkboxText
  );

  return [null, undefined].includes(checkbox.value) ? '' : checkbox.value
};


export const surveyColorValue = function(variableName, siteData) {
  if (!siteData || !siteData.survey_results) {
    return '';
  }

  function getQueryVariable(variable) {
    if( typeof window !== 'undefined' ){
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }
  }

  const urlSurveyValue = getQueryVariable(
    variableName
  );

  // The `variable_name` for primary and secondary color questions
  // should be 'primary_color' and 'secondary_color', respectively.
  // Though, some old site designs use 'design_main_color' instead
  // of 'primary_color' and 'design_secondary_color' instead of
  // 'secondary_color'. In all cases, url primary and secondary colors
  // (i.e. during the preview during the Survey completing) will be
  // sent as `primary_color` and `secondary_color`. Therefore, the
  // last two clauses try to check for this case.
  if( urlSurveyValue ){
    return urlSurveyValue;
  } else if(
    variableName === 'design_main_color' &&
    getQueryVariable( 'primary_color' )
  ){
    return getQueryVariable( 'primary_color' );
  } else if(
    variableName === 'design_secondary_color' &&
    getQueryVariable( 'secondary_color' )
  ){
    return getQueryVariable( 'secondary_color' );
  }


  const result = siteData.survey_results.find(
    r => r.question_type === 'color' && r.variable_name === variableName
  );

  if (!result) {
    return '';
  }

  return result.response_data ? result.response_data : '';
};


export const externalData = function(key, siteData) {
  if (!siteData || !siteData.organization_external_data) {
    return '';
  }

  return siteData.organization_external_data[key]
};


export const submitFormOpportunity = function(dataVarName, successCallback, errorCallback, companyId, siteId) {
  const submitFormOpportunity = function(data, successCallback, errorCallback, companyId, siteId) {
    const payload = {
      form_data: { fields: data },
      ch: 'Website',
      co: companyId,
      s: siteId,
    };

    const xmlHttp = new XMLHttpRequest();

    xmlHttp.open( 'POST', '/api/core/opportunities/form_entry' )
    xmlHttp.setRequestHeader('Content-Type', 'application/json; charset=utf-8');

    xmlHttp.onreadystatechange = function(e) {
      if( xmlHttp.readyState == 4){
        if( xmlHttp.status >= 200 && xmlHttp.status <= 299 ){
          return successCallback()
        } else {
          return errorCallback()
        }
      }
    }

    xmlHttp.send( JSON.stringify(payload) );
  }

  return (
    `
      submitFormOpportunity(
        ${dataVarName},
        ${successCallback},
        ${errorCallback},
        ${companyId},
        ${siteId}
      );
      ${submitFormOpportunity}
    `
  )
}



export const fetchSystemStatus = function() {
  return function( callback ) {
    const xmlHttp = new XMLHttpRequest();

    xmlHttp.open( 'GET', 'https://storage.googleapis.com/go-boost-partners-public/system/system_status.json' );
    xmlHttp.setRequestHeader('Content-Type', 'application/json; charset=utf-8');

    xmlHttp.onreadystatechange = function(e) {
      if( xmlHttp.readyState == 4){
        if( xmlHttp.status === 200 ){
          if( callback ){
            callback( JSON.parse(xmlHttp.responseText) );
          }
        }
      }
    }

    xmlHttp.send();
  };
}