import React from 'react';

const DEFAULT_STATE = {};

export const PaginatedCollectionContext = React.createContext(DEFAULT_STATE);

class PaginatedCollectionProvider extends React.Component {

  render(){
    return (
      <PaginatedCollectionContext.Provider
        value={{
          baseOrCollectionId: this.props.baseOrCollectionId,
          next: this.props.next,
          back: this.props.back,
        }}
      >
        { this.props.children }
      </PaginatedCollectionContext.Provider>
    );
  }
}


export default PaginatedCollectionProvider;