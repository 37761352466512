import React from 'react';

const DEFAULT_STATE = {};

export const RetrieveFeedbackContext = React.createContext(DEFAULT_STATE);

class RetrieveFeedbackProvider extends React.Component {

  constructor(props) {
    super(props);
  }


  componentDidMount(){
    this.injectOverlay();
  }


  componentDidUpdate() {
    this.injectOverlay();
  }


  injectOverlay(){
    const { retrieveFeedback, site } = this.props;

    if(
      typeof document !== 'undefined' &&
      retrieveFeedback &&
      ( !site || !site.is_base_site )
    ){
      const script = document.createElement('script');
      script.src = `https://storage.googleapis.com/go-boost-partners-staging-public/sites_feedback/retrieveFeedback.js`;
      document.head.appendChild(script);
    }
  }



  render(){
    return (
      <RetrieveFeedbackContext.Provider
        value={{
          retrieve_feedback: this.props.retrieveFeedback
        }}
      >
        { this.props.children }
      </RetrieveFeedbackContext.Provider>
    );
  }
}



RetrieveFeedbackProvider.defaultProps = {
  retrieveFeedback: false,
  company: {},
  site: {}
}



export default RetrieveFeedbackProvider