export default function(mediaItem){
  return({
    props: {
      children: mediaItem.is_optimized ? constructPictureChildren(mediaItem.url, mediaItem.optimized_image_urls) : undefined,
      src: mediaItem.is_optimized ? undefined : mediaItem.url
    },
    type: mediaItem.is_optimized ? 'picture' : 'img',
  });
}


export const FILE_SIZES = [
  {
    imageWidthPixels: 400,
    screenWidthPixels: 480
  },
  {
    imageWidthPixels: 640,
    screenWidthPixels: 770
  },
  {
    imageWidthPixels: 1000,
    screenWidthPixels: 1024
  },
  {
    imageWidthPixels: 1280,
    screenWidthPixels: 100000
  },
];



export const parseMediaItemUrl = function(url){
  const urlMatch = url.match(/^https:\/\/storage\.googleapis\.com\/([^\/]+)\/(.+)$/);

  if( urlMatch ){
    const filename = urlMatch[2].split('/').slice(-1)[0];

    return ({
      gcsBucket: urlMatch[1],
      gcsFilepath: urlMatch[2],
      filename,
      fileExtension: filename.split('.').slice(-1)[0]
    })
  }

  return null
}



export const createSrcSet = function(parsedUrl, intendedExtension){
  const filenameWithoutExtension = parsedUrl.filename.replace(new RegExp(`\\.${ parsedUrl.fileExtension }$`), '');

  const pathWithoutFile = parsedUrl.gcsFilepath.replace(new RegExp(`\/${ parsedUrl.filename }$`), '');
  const basePath = `https://storage.googleapis.com/${parsedUrl.gcsBucket}/${pathWithoutFile}`;

  let srcSet = '';
  let sizes = '';

  FILE_SIZES.forEach((s, i) => {
    srcSet += `${ basePath }/${ filenameWithoutExtension }_resized_${ s.imageWidthPixels }.${ intendedExtension } ${ s.imageWidthPixels }w`;

    if( i !== FILE_SIZES.length-1 ){
      srcSet += `, `;
      sizes += `(max-width: ${ s.screenWidthPixels }px) ${ s.imageWidthPixels }px, `;
    } else {
      sizes += `${ s.imageWidthPixels }px`;
    }
  });

  const compressedImageUrl = `${ basePath }/${ filenameWithoutExtension }_compressed.${ intendedExtension }`;

  return { srcSet, sizes, compressedImageUrl };
}



export const constructPictureChildren = function(mediaItemUrl, optimizedImageUrls){
  const compressedUrls = optimizedImageUrls.split(',').filter(u => u.includes('_compressed.'));

  // We use the `picture` tag with children `source` and `img`
  // tags for image optimization. We do not need to recognize
  // the browser because the browser will render the first image
  // it "recognizes" and meet the qualifications
  const optimizedImageTypes = [
    {
      type: 'image/webp',
      extension: '.webp',
    },
    {
      type: 'image/jpg2',
      extension: '.jpeg2000',
    }
  ];

  optimizedImageTypes.forEach(
    t => t['url'] = compressedUrls.find(u => u.endsWith( t.extension ))
  );

  const children = optimizedImageTypes.map(
    (t, i) => ({
      type: "source",
      key: i,
      props: {
        type: t.type,
        srcSet: t.url,
      }
    })
  )

  children.push({
    type: "img",
    key: optimizedImageTypes.length,
    props: {
      srcSet: mediaItemUrl,
    }
  });


  return children
}