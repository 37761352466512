import React from 'react';
import Axios from 'axios';

const DEFAULT_STATE = {};

export const CollectionElementCacheContext = React.createContext(DEFAULT_STATE);

class CollectionElementCacheProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = props.state || {};
  }


  fetchCachedCollectionElement(baseOrCollectionIdAndSlug) {
    const collectionElement = this.state[ baseOrCollectionIdAndSlug ];
    // console.log('Found collectionElement', collectionElement);
    return collectionElement;
  }



  fetchRemoteCollectionElement(baseOrCollectionIdAndSlug) {
    if(typeof window !== 'undefined') {
      const split = (baseOrCollectionIdAndSlug || '').split('-');
      const baseOrCollectionId = +split[0];
      let collectionElementSlug = split.slice(1).join('-');

      if (!collectionElementSlug) {
        // Note: This occurs in block preview if there's no slug.
        return Promise.resolve({});
      }

      // console.log('fetchRemoteCollectionElement', baseOrCollectionIdAndSlug)
      return Axios.get(
        `/api/sites/collection_elements/collections/${baseOrCollectionId}/slugs/${collectionElementSlug}`,
        {
          params: {
            role_type: this.props.site.role_type,
            role_type_id: this.props.site.role_type_id,
          }
        }
      )
        .then(response => {
          // console.log('CollectionElementCacheProvider fetchRemoteCollectionElement', response);
          // Note - we need to decide whether or not to camelcase these props.
          const collectionElement = response.data.collection_element;
          this.setState({ [baseOrCollectionIdAndSlug]: collectionElement });
          return collectionElement;
        });
    } else {
      // console.log('fetchRemoteCollectionElement - `window` is undefined');
      return Promise.resolve(null);
    }
  }

  render(){
    return (
      <CollectionElementCacheContext.Provider
        value={{
          fetchCachedCollectionElement: this.fetchCachedCollectionElement.bind(this),
          fetchRemoteCollectionElement: this.fetchRemoteCollectionElement.bind(this),
        }}
      >
        { this.props.children }
      </CollectionElementCacheContext.Provider>
    );
  }
}

export default CollectionElementCacheProvider;