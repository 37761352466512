import React from 'react';
import Axios from 'axios';

const DEFAULT_STATE = {};

export const MediaItemCacheContext = React.createContext(DEFAULT_STATE);

class MediaItemCacheProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = props.state || {};
  }


  fetchCachedMediaItem(id) {
    let mediaItem = this.state[id];
    return mediaItem;
  }


  fetchRemoteMediaItem(id) {
    if(typeof window !== 'undefined') {
      return Axios.get(`/api/core/media_items/${id}`)
        .then(response => {
          // console.log('MediaItemCacheProvider fetchRemoteMediaItem', response);
          const mediaItem = response.data.media_item;
          this.setState({ [mediaItem.id]: mediaItem });
          return mediaItem;
        });
    } else {
      // console.log('fetchRemoteMediaItem - `window` is undefined');
      return Promise.resolve(null);
    }
  }

  render(){
    return (
      <MediaItemCacheContext.Provider
        value={{
          fetchCachedMediaItem: this.fetchCachedMediaItem.bind(this),
          fetchRemoteMediaItem: this.fetchRemoteMediaItem.bind(this)
        }}
      >
        { this.props.children }
      </MediaItemCacheContext.Provider>
    );
  }
}

export default MediaItemCacheProvider;