import React from 'react';
import { withRouter } from 'react-router-dom';

import withContext from './withContext';
import { PageContext } from './PageProvider';


const DEFAULT_STATE = {};

export const CollectionElementContext = React.createContext(DEFAULT_STATE);

class CollectionElementProvider extends React.Component {

  constructor(props) {
    super(props);
    // this.props.updateTitleWithContext(props);
    this.updateTitleIfPageForElement();
  }



  componentDidUpdate(prevProps){
    if( this.props !== prevProps ) {
      this.updateTitleIfPageForElement();
    }
  }



  updateTitleIfPageForElement(){
    const elementPathname = this.props.page && this.props.page.path ?
        this.props.page.path.replace(
          /:[^\/]+/,
          this.props.element.slug
        )
      :
        null
    ;

    if(
      elementPathname &&
      this.props.history.location.pathname === elementPathname
    ) {
      this.props.updateTitleWithContext(this.props);
    }
  }



  render(){
    return (
      <CollectionElementContext.Provider
        value={{
          collection: this.props.collection,
          element: this.props.element,
        }}
      >
        { this.props.children }
      </CollectionElementContext.Provider>
    );
  }
}


CollectionElementProvider.defaultProps = {
  updateTitleWithContext: () => {},
  element: {}
};

export default withContext(
  PageContext,
  withRouter(
    CollectionElementProvider
  )
);