import React from 'react';

const DEFAULT_STATE = {};

export const MediaItemContext = React.createContext(DEFAULT_STATE);

class MediaItemProvider extends React.Component {

  constructor(props) {
    super(props);
  }


  render(){
    return (
      <MediaItemContext.Provider
        value={{
          media_item: this.props.mediaItem
        }}
      >
        { this.props.children }
      </MediaItemContext.Provider>
    );
  }
}

export default MediaItemProvider