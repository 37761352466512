import React from 'react';
import SiteNode from './SiteNode';
import BlockSearchProvider from './BlockSearchProvider';



class BlockSearch extends React.Component {
  render(){
    return (
      <BlockSearchProvider
        blockSearchId={this.props.blockSearchId}
      >
        <SiteNode node={this.props.children} />
      </BlockSearchProvider>
    );
  }
}



export default BlockSearch;