import React from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import withContext from './withContext';
import { SiteDataContext } from './SiteDataProvider';
import { BlockSearchCacheContext, constructBlockSearchHash, deconstructBlockSearchHash } from './BlockSearchCacheProvider';


const DEFAULT_STATE = {
  elements: [],
  total: 0,
  query: null,
  searchedQuery: null,
  limit: null,
  searchedLimit: null,
  offset: null,
  searchedOffset: null,
  next: null,
  back: null,
  search: null,
};


const BIND_SEARCH_FIELD_TIMEOUT_MILLISECONDS = 250;


export const BlockSearchContext = React.createContext(DEFAULT_STATE);

class BlockSearchProvider extends React.Component {
  constructor(props){
    super(props);

    const blockSearchHash = deconstructBlockSearchHash(
      props.blockSearchId || ''
    );

    const fetchedOffset = fetchOffset(
      blockSearchHash.slug,
      props.history.location.search
    );
    const offset = fetchedOffset !== null ? fetchedOffset : blockSearchHash.offset;
    blockSearchHash.offset = offset;

    const fetchedLimit = fetchLimit(
      blockSearchHash.slug,
      props.history.location.search
    );
    const limit = fetchedLimit !== null ? fetchedLimit : blockSearchHash.limit;
    blockSearchHash.limit = limit;

    const fetchedQuery = fetchQuery(
      blockSearchHash.slug,
      props.history.location.search
    );
    const query = fetchedQuery !== null ? fetchedQuery : blockSearchHash.query;
    blockSearchHash.query = query;


    const newState = {
      ...DEFAULT_STATE
    };


    if( blockSearchHash.limit ){
      newState.limit = blockSearchHash.limit;
    }

    if( blockSearchHash.offset ){
      newState.offset = blockSearchHash.offset;
    }

    if( blockSearchHash.query ){
      newState.query = blockSearchHash.query;
    }


    const cachedBlockSearch = props.fetchCachedBlockSearch(
      constructBlockSearchHash( blockSearchHash )
    );
    if( cachedBlockSearch ){
      newState.elements = cachedBlockSearch.elements;
      newState.total = cachedBlockSearch.total;


      const { search, next, back } = this.constructSearchParams({
        query: newState.query,
        limit: newState.limit,
        offset: newState.offset,
        total: cachedBlockSearch.total,
        elements: cachedBlockSearch.elements,
        searchedQuery: newState.query,
        searchedOffset: newState.offset,
        blockSearchId: constructBlockSearchHash(
          blockSearchHash
        ),
        pathname: props.history.location.pathname
      });


      newState.search = search;
      newState.next = next;
      newState.back = back;
    }


    this.state = {
      ...newState
    };


    this.bindSearchField();
  }



  componentDidUpdate(oldProps){
    const { query, limit, offset } = this.fetchSearchParams();

    if(
      ![null, undefined].includes( this.state.query ) &&
      (
        this.state.searchedQuery !== query ||
        this.state.searchedLimit !== limit ||
        this.state.searchedOffset !== offset
      )
    ){
      return this.search()
    }
  }



  onChangeQueryField(e, callback){
    return this.setState(
      { query: e.target.value },
      callback ? callback : this.setSearchParams
    )
  }



  constructSearchParams(
    params={
      query,
      limit,
      offset,
      total,
      elements,
      searchedQuery,
      searchedOffset,
      blockSearchId,
      pathname
    }
  ){
    const { query, limit, offset, total, elements, searchedQuery, searchedOffset, blockSearchId, pathname } = ( params || {} );


    const blockSearchHash = deconstructBlockSearchHash(
      blockSearchId || ''
    );

    if( !blockSearchHash.slug ){
      return;
    }


    let search;
    let next;
    let back;

    // search
    // All searches should start from the beginning (unless an initial
    // offset is provided for the initial query)
    const searchParams = {};

    if( query ){
      searchParams[`${ blockSearchHash.slug }_query`] = query;
    }

    if( limit ){
      searchParams[`${ blockSearchHash.slug }_limit`] = limit;
    }

    if(
      blockSearchHash.query &&
      blockSearchHash.query === query &&
      blockSearchHash.offset
    ){
      searchParams[`${ blockSearchHash.slug }_offset`] = blockSearchHash.offset;
    }


    // next
    // Only available if the query is the same
    const nextParams = {};
    if(
      total &&
      query === searchedQuery &&
      (
        !searchedOffset ||
        searchedOffset < ( total - 1 )
      )
    ){
      if( query ){
        nextParams[`${ blockSearchHash.slug }_query`] = query;
      }

      if( limit ){
        nextParams[`${ blockSearchHash.slug }_limit`] = limit;
      }

      nextParams[`${ blockSearchHash.slug }_offset`] = (
        ( searchedOffset || 0 ) + elements.length
      );
    }


    // back
    // Only available if the query is the same
    const backParams = {};
    if(
      total &&
      query === searchedQuery &&
      (
        searchedOffset &&
        searchedOffset > 0
      )
    ){
      if( query ){
        backParams[`${ blockSearchHash.slug }_query`] = query;
      }

      if( limit ){
        backParams[`${ blockSearchHash.slug }_limit`] = limit;
      }

      backParams[`${ blockSearchHash.slug }_offset`] = Math.max(
        0,
        ( searchedOffset || 0 ) - elements.length
      );
    }


    if( query ){
      const searchParamsString = Object.keys( searchParams ).map(
        p => `${ p }=${ searchParams[ p ] }`
      ).join('&');

      search = pathname;
      if( searchParamsString ){
        search += `?${ searchParamsString }`;
      }
    }

    if( query && Object.keys( nextParams ).length ){
      const nextParamsString = Object.keys( nextParams ).map(
        p => `${ p }=${ nextParams[ p ] }`
      ).join('&');

      next = pathname;
      if( nextParamsString ){
        next += `?${ nextParamsString }`;
      }
    }

    if( query && Object.keys( backParams ).length ){
      const backParamsString = Object.keys( backParams ).map(
        p => `${ p }=${ backParams[ p ] }`
      ).join('&');

      back = pathname;
      if( backParamsString ){
        back += `?${ backParamsString }`;
      }
    }


    return({
      search,
      next,
      back
    });
  }



  setSearchParams(){
    const { search, next, back } = this.constructSearchParams({
      query: this.state.query,
      limit: this.state.limit,
      offset: this.state.offset,
      total: this.state.total,
      elements: this.state.elements,
      searchedQuery: this.state.searchedQuery,
      searchedOffset: this.state.searchedOffset,
      blockSearchId: this.props.blockSearchId,
      pathname: window.location.pathname
    });


    return this.setState(
      { search, next, back }
    );
  }



  fetchSearchParams(){
    const blockSearchHash = deconstructBlockSearchHash(
      this.props.blockSearchId || ''
    );

    if( typeof window === 'undefined' || !blockSearchHash.slug ){
      return ({
        query: blockSearchHash.query,
        limit: blockSearchHash.limit,
        offset: blockSearchHash.offset,
      });
    }


    const query = fetchQuery(
      blockSearchHash.slug,
      window.location.search
    );

    const limit = fetchLimit(
      blockSearchHash.slug,
      window.location.search
    );

    const offset = fetchOffset(
      blockSearchHash.slug,
      window.location.search
    );


    return ({
      query,
      limit,
      offset
    });
  }



  search(){
    const searchParams = this.fetchSearchParams();

    const { query } = searchParams;
    let { limit, offset } = searchParams;


    limit = limit && +limit >= 0 ? +limit : undefined;

    offset = offset || undefined;


    const params = {
      offset,
      limit,
      q: query
    };


    return Axios.get(
      `/api/sites/${ this.props.siteData.site.id }/_search`,
      { params }
    )
      .then(response => {
        const elements = response.data.published_site_pages;

        const total = response.data.total;


        return this.setState(
          {
            elements,
            total,
            searchedQuery: searchParams.query,
            searchedLimit: searchParams.limit,
            searchedOffset: searchParams.offset
          },
          this.setSearchParams
        );
      })
      .catch(error => {
        return null;
      })
    ;
  }



  bindSearchField(){
    const blockSearchHash = deconstructBlockSearchHash(
      this.props.blockSearchId || ''
    );


    if(
      !blockSearchHash ||
      typeof document === 'undefined'
    ){
      return;
    }


    const field = document.getElementById( blockSearchHash.field );
    if( !field ){
      return setTimeout(
        this.bindSearchField.bind( this ),
        BIND_SEARCH_FIELD_TIMEOUT_MILLISECONDS
      );
    }


    if( field.onchange ){
      return;
    }


    field.onchange = this.onChangeQueryField.bind( this );

    field.addEventListener(
      'keyup',
      e => (function(e){
        if( e.key === 'Enter' || e.keyCode === 13 ){
          return this.onChangeQueryField.bind( this )(
            e,
            () => {
              if( this.state.search ){
                window.location.search = ( this.state.search || '' ).slice(1)
              };
            }
          );
        }
      }).bind(this)( e )
    )


    const query = (
      blockSearchHash.query ||
      this.state.query
    );

    if( query && field.value !== query ){
      field.value = query;
    }


    return;
  }



  render(){
    return (
      <BlockSearchContext.Provider
        value={{
          elements: this.state.elements,
          total: this.state.total,
          next: this.state.next,
          back: this.state.back,
          search: this.state.search,
        }}
      >
        { this.props.children }
      </BlockSearchContext.Provider>
    );
  }
}



BlockSearchProvider.defaultProps = {
  blockSearchId: '',
}



export default withContext(
  BlockSearchCacheContext,
  SiteDataContext,
  withRouter( BlockSearchProvider )
);



export const fetchLimit = (slug, search) => {
  if( slug && search ){
    const limit = search.match( new RegExp( `${ slug }_limit=(\\d+)` ) );

    return limit ? +limit[1] : null
  }

  return null
}



export const fetchQuery = (slug, search) => {
  if( slug && search ){
    const query = search.match( new RegExp( `${ slug }_query=([^\\/&]+)` ) );

    return query ? query[1] : null
  }

  return null
}



export const fetchOffset = (slug, search) => {
  if( slug && search ){
    const offset = search.match( new RegExp( `${ slug }_offset=([^\\/&]+)` ) );

    return offset ? +offset[1] : null
  }

  return null
}