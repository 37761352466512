import React from 'react';
import withContext from './withContext';
import SiteNode from './SiteNode';
import { BlockSearchContext } from './BlockSearchProvider';
import BlockSearchElementProvider from './BlockSearchElementProvider';



class BlockSearchElement extends React.Component {
  render(){
    return (
      this.props.elements.map((e,i) => (
        <BlockSearchElementProvider key={i} element={e}>
          <SiteNode node={this.props.children}/>
        </BlockSearchElementProvider>
      ))
    );
  }
}



BlockSearchElement.defaultProps = {
  elements: [],
}



export default withContext(BlockSearchContext, BlockSearchElement);