import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import BlockCacheProvider from './components/BlockCacheProvider';
import CollectionCacheProvider from './components/CollectionCacheProvider';
import BlockSearchCacheProvider from './components/BlockSearchCacheProvider';
import CollectionElementCacheProvider from './components/CollectionElementCacheProvider';
import MediaItemCacheProvider from './components/MediaItemCacheProvider';
import SiteDataProvider from './components/SiteDataProvider';
import SitePreviewProvider from './components/SitePreviewProvider';
import RetrieveFeedbackProvider from './components/RetrieveFeedbackProvider';
import SitePagesProvider from './components/SitePagesProvider';
import Site from './components/Site';
import Block from './components/Block';

const jsx = (
  <Router>
    <BlockCacheProvider siteId={window.CLIENT_DATA.site.id} state={window.CLIENT_DATA.blocksByBaseOrBlockId}>
      <MediaItemCacheProvider state={window.CLIENT_DATA.mediaItemsById}>
        <BlockSearchCacheProvider state={window.CLIENT_DATA.cachedBlockSearchState}>
          <CollectionCacheProvider state={window.CLIENT_DATA.cachedCollectionState} site={window.CLIENT_DATA.site}>
            <CollectionElementCacheProvider state={window.CLIENT_DATA.collectionElementsByCollectionIdAndSlug} site={window.CLIENT_DATA.site}>
              <SitePagesProvider pages={window.CLIENT_DATA.pages}>
                <SiteDataProvider siteData={window.CLIENT_DATA.siteData}>
                  <SitePreviewProvider isPreview={window.CLIENT_DATA.isPreview} highlightBlocks={window.CLIENT_DATA.highlightBlocks} preventDefault={window.CLIENT_DATA.preventDefault}>
                    <RetrieveFeedbackProvider retrieveFeedback={window.CLIENT_DATA.retrieveFeedback} company={window.CLIENT_DATA.siteData.company} site={window.CLIENT_DATA.site}>
                      {
                        window.location.pathname.match(/^\/blocks\/sites\/\d+\/blocks\/\d+\/preview$/) ?
                          <div data-base-or-block-id={window.CLIENT_DATA.block.id}>
                            <Block baseOrBlockId={window.CLIENT_DATA.block.id} />
                          </div>
                          :
                          <Site />
                      }
                    </RetrieveFeedbackProvider>
                  </SitePreviewProvider>
                </SiteDataProvider>
              </SitePagesProvider>
            </CollectionElementCacheProvider>
          </CollectionCacheProvider>
        </BlockSearchCacheProvider>
      </MediaItemCacheProvider>
    </BlockCacheProvider>
  </Router>
);

const gbSite = document.getElementById('gbSite');
ReactDOM.hydrate(jsx, gbSite);