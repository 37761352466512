// Global Constants
import GlobalConstantsProduction from '../global_constants/global_constants.production.js';
import GlobalConstantsStaging from '../global_constants/global_constants.staging.js';
import GlobalConstantsDemo from '../global_constants/global_constants.demo.js';
import GlobalConstantsDevelopment from '../global_constants/global_constants.development.js';


const GlobalConstants = {
  production: GlobalConstantsProduction,
  staging: GlobalConstantsStaging,
  demo: GlobalConstantsDemo,
  development: GlobalConstantsDevelopment,
}[process.env.NODE_ENV || 'development'];

export default GlobalConstants;