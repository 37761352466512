import React from 'react';
import { withRouter } from 'react-router-dom';
import { surveyCheckboxValue, surveyInputValue, surveyColorValue } from './siteDataHelpers';

const DEFAULT_STATE = {};

export const SiteDataContext = React.createContext(DEFAULT_STATE);

class SiteDataProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      siteData: {
        ...this.props.siteData,
        site: this.props.siteData.site || {} // TODO - remove this after we deploy python code for sites.
      }
    }

  }


  render(){
    const { siteData } = this.state;

    return (
      <SiteDataContext.Provider
        value={{
          siteData,
        }}
      >
        { this.props.children }
      </SiteDataContext.Provider>
    );
  }
}


export default withRouter(SiteDataProvider);
