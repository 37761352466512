import React from 'react';
import Axios from 'axios';

const DEFAULT_STATE = {};

export const BlockCacheContext = React.createContext(DEFAULT_STATE);

class BlockCacheProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = props.state || {};
  }

  getBlock(baseOrBlockId) {
    return this.state[
      Object.keys(this.state).find(k => {
        const b = this.state[k];
        return (b.base_block_id == baseOrBlockId || b.id == baseOrBlockId);
      })
    ];
  }


  fetchCachedBlock(baseOrBlockId) {
    // console.log('BlockCacheProvider.fetchCachedBlock', baseOrBlockId);
    let block = this.getBlock(baseOrBlockId);
    if (block) {
      // console.log('Found block', block);
    } else {
      console.log('Block', baseOrBlockId, 'not found.');
    }
    return block;
  }


  fetchRemoteBlock(baseOrBlockId) {
    const { siteId } = this.props;
    if (window) {
      return Axios.get(`/api/sites/${siteId}/blocks/${baseOrBlockId}`)
        .then(response => {
          // console.log('BlockCacheProvider fetchRemoteBlock', response);
          const block = response.data.block;
          this.setState({ [block.base_block_id || block.id]: block });
          return block;
        });
    } else {
      // console.log('fetchRemoteBlock - `window` is undefined');
      return Promise.resolve({ data: null });
    }
  }

  fetchRemoteBlockJs(baseOrBlockId) {
    const { siteId } = this.props;
    if (window) {
      return Axios.get(`/api/sites/${ siteId }/blocks/${ baseOrBlockId }/js`)
        .then(response => {
          let js = response.data;

          try {
            js = JSON.parse( js );
          } catch (error) {}

          const block = {
            ...this.state[ baseOrBlockId ],
            js
          };


          this.setState({ [baseOrBlockId]: block });

          return block
        });
    } else {
      // console.log('fetchRemoteBlock - `window` is undefined');
      return Promise.resolve({ data: null });
    }
  }

  render() {
    return (
      <BlockCacheContext.Provider
        value={{
          siteId: this.props.siteId,
          fetchCachedBlock: this.fetchCachedBlock.bind(this),
          fetchRemoteBlock: this.fetchRemoteBlock.bind(this),
          fetchRemoteBlockJs: this.fetchRemoteBlockJs.bind(this),
          getBlock: this.getBlock.bind(this),
        }}
      >
        {this.props.children}
      </BlockCacheContext.Provider>
    );
  }
}


export default BlockCacheProvider;