import React from 'react';

const DEFAULT_STATE = {};

export const NestedCollectionElementContext = React.createContext(DEFAULT_STATE);


const DEFAULT_ALIAS = 'element';


class NestedCollectionElementProvider extends React.Component {

  render(){
    const alias = (
      this.props.alias || DEFAULT_ALIAS
    );


    const nestedElements = (
      this.props.nestedElements || {}
    );

    if( alias !== DEFAULT_ALIAS ){
      nestedElements[ alias ] = this.props.element;
    }


    return (
      <NestedCollectionElementContext.Provider
        value={{
          ...nestedElements,
          nestedElements,
          [alias]: this.props.element,
        }}
      >
        { this.props.children }
      </NestedCollectionElementContext.Provider>
    );
  }
}


NestedCollectionElementProvider.defaultProps = {
  element: {},
  alias: null,
  nestedElements: {}
};


export default NestedCollectionElementProvider;