import React from 'react';


const DEFAULT_STATE = {
  element: {}
};


export const BlockSearchElementContext = React.createContext(DEFAULT_STATE);

class BlockSearchElementProvider extends React.Component {
  constructor(props){
    super(props);


    this.state = {
      ...DEFAULT_STATE
    };
  }



  render(){
    return (
      <BlockSearchElementContext.Provider
        value={{
          element: this.props.element,
        }}
      >
        { this.props.children }
      </BlockSearchElementContext.Provider>
    );
  }
}


export default BlockSearchElementProvider;