import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import withContext from './withContext';
import PageProvider from './PageProvider';
import Block from './Block';
import SiteNode from './SiteNode';

import { BlockCacheContext } from './BlockCacheProvider';
import { SitePagesContext } from './SitePagesProvider';

class Site extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      document.dispatchEvent(new Event('routechange'));
      document.dispatchEvent(new Event('pagechange'));
    }
  }


  render() {
    const { pages } = this.props;

    return (
      <React.Fragment>
        <Switch>
          {
            pages.map(p => (
              <Route
                exact
                key={p.path}
                path={p.path}
                render={props => (
                  <PageProvider page={p} setInitialTitle={this.props.setInitialTitle}>
                    <div id="gbPage" data-base-or-block-id={p.baseOrBlockId}>
                      <Block
                        {...props}
                        baseOrBlockId={p.baseOrBlockId}
                      />
                    </div>
                  </PageProvider>
                )}
              />
            ))
          }
        </Switch>
      </React.Fragment>
    );
  }

}


Site.defaultProps = {
  pages: [],
  retrieveFeedback: false
};


export default withRouter(
  withContext(
    BlockCacheContext,
    SitePagesContext,
    Site
  )
);





